@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Brands:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lao+MN:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-lao-mn: "Lao MN";
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";

  /* font sizes */
  --font-size-3xl: 1.38rem;
  --font-size-base: 1rem;
  --font-size-17xl: 2.25rem;

  /* Colors */
  --color-white: #fff;
  --color-darkslategray: #0e5e53;

  /* Gaps */
  --gap-xs: 0.75rem;
}
