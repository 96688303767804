@import url("https://fonts.googleapis.com/css2?family=Lao+MN:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fakt+Pro:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

* {

  font: "Lao MN";
  overflow: hidden;
}

.coming-soon-screen {
  background-color: #000;
  height: 100%;
  overflow: hidden;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

.background-img {
  position: absolute;
  width: 100%;
  height: 42rem;
  left:0;
  object-fit: cover;
}

.logo {
  position: relative;
  width: 7rem;
  top:2rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(16, 94, 83, 0.6);
  width: 100%;
  height: 52.88rem;
}

.social-icon {
  position: relative;
  width: 0.5rem;
  z-index: 5;
}

.item {
  position: absolute;
  width: 100%;
  top: 30rem;
  margin: 0 auto;
  gap: 5rem;
  font-size: var(--font-size-base);
  font-family: var(--font-font-awesome-5-brands);
}

.frame {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
    top: 7rem;
  font-family: var(--font-lao-mn);
 
}

.about {
  font-size: 80%;
  width: 70%;
  margin: 7.5rem auto;
  color:  var(--color-white);
  font-family: 'Montserrat', sans-serif;
}

@media (min-width:641px){
  .coming-soon{
    width: 70%;
    margin: 7.5rem auto;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  .about {
    font-size: 100%;
    width: 70%;
    margin: 7.5rem auto;
    color:  var(--color-white);
    font-family: 'Montserrat', sans-serif;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(16, 94, 83, 0.6);
    height: 100%;
  }
  
  .item {
    position: absolute;
    top: 10rem;
    left: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3rem;
    font-size: var(--font-size-base);
    font-family: var(--font-font-awesome-5-brands);
  }
  .background-img {
    position: absolute;
    height: 100%;
    left:0;
    object-fit: cover;
  }
  .coming-soon-screen {
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-montserrat);
}
.logo {
  position: relative;
  top:2rem;
  width: 7rem;
}

  
}